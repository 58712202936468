import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    background,
    container,
    downloadButton,
    header,
    text,
} from './contest-regulations.module.scss';
import { IPage } from '../models/page.model';

import MainLayout from '../layouts/main-layout';
import BackgroundRepeat from '../layouts/background-repeat';
import Button from '../components/atoms/button';
import Markdown from '../components/hoc/markdown';

interface IContestRegulationsPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const ContestRegulationsPage: React.FC<IContestRegulationsPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page } = data;
    const { regulation } = page || {};
    const { media, content, name } = regulation || {};
    const downloadUrl = media?.localFile.publicURL;

    return (
        <MainLayout className={layout} page={page} showRecipesSlider={false}>
            <BackgroundRepeat type={4} className={background}>
                <div className={container}>
                    {name && <h1 className={header}>{name}</h1>}
                    {content && <Markdown className={text}>{content}</Markdown>}
                </div>
                {downloadUrl && (
                    <Button
                        className={downloadButton}
                        as="externalLink"
                        to={downloadUrl}
                        download={name}
                    >
                        {t('regulation.download')}
                    </Button>
                )}
            </BackgroundRepeat>
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default ContestRegulationsPage;